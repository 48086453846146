.list {
    width: 900px;
    padding-top: 20px;
    margin-left: 400px;
}

.header {

}

.total_count {

}

