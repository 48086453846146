.wrapper {
    margin: 15px 0;
    background-color: white;
    height: 230px;
}

.image {
    width: 370px;
    height: 230px;
    float: left;
    background-size: cover;
    background-position: center center;
}

.content {
    padding: 20px;
    margin-left: 370px;
    position: relative;
    height: 100%;
}

.info {
    display: block;
}

.info > span {
    display: block;
}

.right_side {
    position: absolute;
    width: 120px;
    top: 50px;
    right: 10px;
}

.price {
    font-size: 22px;
    color: #4a436b;
}

.sold {
    font-size: 20px;
    color: red;
}

.footer {
    position: absolute;
    bottom: 10px;
    font-size: 14px;
}

.footer span:first-child {
    margin-right: 15px;
}

.title {
    color: #4a436b;
    font-size: 24px;
}