.wrapper {
    float: left;
    width: 360px;
    background-color: white;
    padding: 25px;
    height: 100vh;
    position: fixed;
}

.row {
    margin-top: 10px;
    margin-bottom: 10px;
}

.search {
    margin-top: 10px;
}